"use client";

import { useRouter } from "next/navigation";
import {
  ChangeEvent,
  FC,
  useCallback,
  useState,
} from "react";

import { SearchBar } from "../SearchBar/SearchBar";

export interface BlogSearchBarProps {
  initialSearchString?: string;
}

export const BlogSearchBar: FC<BlogSearchBarProps> = ({
  initialSearchString,
}) => {

  const [ searchString, setSearchString ] = useState(initialSearchString);

  const onTextChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setSearchString(e.target.value), []);

  const router = useRouter();

  const onEnter = () => {
    void router.push(`/articles/search?query=${searchString}`);
  };

  return (
    <SearchBar
      data-testid="blog-search-bar"
      component={BlogSearchBar.name}
      name="Search"
      placeholder="Search KatKin"
      label="Search KatKin"
      value={searchString}
      onEnter={onEnter}
      onChange={onTextChange}
    />
  );
};
